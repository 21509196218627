<template>
	<div id="app" style="background-color: #000000;">
		<video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player></div>
</template>

<script>
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
export default {
	name: 'App',
	components: {
		videoPlayer
	},
	data() {
		return {
			playerOptions: {
				//playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
				autoplay: false, // 如果为true,浏览器准备好时开始回放。
				width: document.documentElement.clientWidth,
				muted: false, // 默认情况下将会消除任何音频。
				loop: false, // 是否视频一结束就重新开始。
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				language: 'zh-CN',
				aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [
					{
						type: 'application/x-mpegURL', // 类型
						src: '' // url地址
					}
				],
				poster: '', // 封面地址
				notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
				controlBar: {
					timeDivider: false,
					durationDisplay: false
				},
				flash: { hls: { withCredentials: false }},
				html5: { hls: { withCredentials: false }},
			}
		};
	},

	computed: {
		player() {
			return this.$refs.videoPlayer.player;
		}
	},
	mounted() {

		let videoUrl = this.getQuery('videoUrl')
		console.log('>>>>>>> url: {}', videoUrl)
		
		this.playerOptions.sources[0].src = videoUrl
		
	},
	
	methods: {
		// 获取url上指定键的值
		getQuery(keyName) {
			let tk = decodeURIComponent((new RegExp('[?|&]' + keyName + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href))[1].replace(/\+/g, '%20')) || null
			return tk
		},
	}
};
</script>

<style>
#app {
	background-color: #000000;
	min-height: 100%;
	max-height: 100%;
}
</style>
